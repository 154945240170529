@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@800&display=swap');

$fontfamily: Roboto, sans serif;

body {
    font-family: $fontfamily; }

.root {
    background-color: teal;
    width: 80%;
    font-family: $fontfamily;
    position: "absolute";
    transform: "translate(-50%, -50%)";
    top: "50%";
    left: "50%";
    flex-grow: 1;
    size: "auto";
    background: "LightSlateGray";
    width: 500; }
.infoWindow {
    width: 200px;
    height: 200px; }
.map-div {
    background-color: white;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    p {
        text-align: center; } }
.modal-img {
    max-width: "30%"; }
.underline {
    text-decoration: underline; }
.homepage {
    font-size: 2rem;
    overflow: hidden; }
.home-bg {
    position: relative;
    width: 110vw;
    overflow: hidden;
    transform: translateX(-5%);
    border: 1px solid black;
    height: 60vh;
    background-size: cover;
    img {
        width: 100%;
        height: 130%; } }
.intro {
    left: 20%;
    position: absolute;
    top: 20%;
    color: white;
    font-size: 1.5rem;
    width: 80%;
    align-items: center;
    transform: translate(-10%, -10%);
    text-shadow: 2px 2px black;
    @media only screen and (max-height: 800px) {
        font-size: 1.2rem; }
    @media only screen and (max-height: 500px) {
        font-size: 1rem; }

    @media only screen and (max-width: 1000px) and (min-width: 0) {
        font-size: 1.2rem; }
    @media only screen and (max-width: 550px) and (min-width: 0) {
        font-size: 0.9rem; }
    @media only screen and (max-width: 360px) and (min-width: 0) {
        top: 15%;
        font-size: 0.8rem; } }
.name {
    font-family: Open Sans;
    font-size: 5rem;
    @media only screen and (max-height: 800px) {
        font-size: 3rem; }
    @media only screen and (max-height: 500px) {
        font-size: 2rem; }
    @media only screen and (max-width: 1000px) and (min-width: 0) {
        font-size: 3rem; }
    @media only screen and (max-width: 550px) and (min-width: 0) {
        font-size: 2rem; }
    @media only screen and (max-width: 360px) and (min-width: 0) {
        font-size: 1.5rem; } }
.scroll-to-top {
    display: none;
    @media only screen and (max-width: 550px) and (min-width: 0) {
        display: block; }
    @media only screen and (max-width: 360px) and (min-width: 0) {
        display: block; } }
